import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { SITE_URL } from '../../apis/baseURL';
import { DolEventCardNewV2 } from './Card/DolEventCardNewV2';

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  row-gap: 24px;
  cursor: pointer;
  .divider-4,
  .divider-5 {
    display: block;
  }
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    .divider-4,
    .divider-5 {
      display: none;
    }
  }
`;

const getPageUrl = url => {
  if (url) {
    return `${SITE_URL}/${url}`;
  }
  return null;
};

export const DolNewsFeatureList = props => {
  const { data } = props;

  return (
    <Container>
      {data?.map((eachNews, idx) => (
        <DolEventCardNewV2
          key={idx}
          idx={idx}
          hrefUrl={getPageUrl(eachNews?.page?.url)}
          alt={eachNews?.cover?.alt}
          coverUrl={eachNews?.cover?.url}
          description={eachNews?.description}
          name={eachNews?.name}
        />
      ))}
    </Container>
  );
};

DolNewsFeatureList.propTypes = {
  data: PropTypes.array
};
