import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { headingStyles } from './sectionStyles';

const Container = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 48px;
  .section-title {
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  ${headingStyles}
`;

export const DolNewsSection = props => {
  const { title, content } = props;

  return (
    <Container>
      {title}
      {content}
    </Container>
  );
};

DolNewsSection.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node
};
